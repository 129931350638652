import * as React from 'react'
import { useState } from 'react'
import '../index.css'
import '../Error.css'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Loader from './loader'
import { Modal } from '@mui/material'
//import Error from './errorMessage'

function Copyright(props) {
    return (
        <Typography variant="body2" color="#4C61B3" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="">
                Niku technology.
            </Link>
            {' All rights reserved. '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

async function loginUser(credentials) {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    let requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(credentials),
        redirect: 'follow'
    }

    return fetch(process.env.REACT_APP_SECRET_URL + '/Bill/v1/loginFacturacionColegio', requestOptions)
        .then(response => response.json())
}

export default function Login({ setToken }) {

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        const token = await loginUser({
            user: data.get('user'),
            password: data.get('password')
        })

        if (token?.error) {
            setLoading(false)
            setError(true)
            setErrorMessage(token.error)
            handleOpen(true)
        } else {
            setToken(token)
            setLoading(false)
        }
    }

    return (
        <div className='mainframe'>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                }}
            >
                <div className='login-headline'>
                    <img height={115}
                        src='logo_CR.png'
                        alt='Logo Colegio Rotherham'
                    >
                    </img>
                    <h2>Facturación</h2>
                </div>
                <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                        required
                        id="user"
                        label="Usuario"
                        name="user"
                        autoFocus
                        variant="standard"
                    />
                    <TextField
                        margin="normal"
                        required
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        variant="standard"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        className='login-button'
                        sx={{
                            typography: {
                                "fontFamily": `"Fira sans", sans-serif`,
                                "fontSize": 14,
                                "fontWeightLight": 300,
                                "fontWeightRegular": 400,
                                "fontWeightMedium": 500
                            },
                            width: 100,
                            maxWidth: 100,
                            margin: 2,
                            border: '4px solid',
                            borderRadius: 0,
                            color: 'white',
                            background: '#4C61B3',
                            '&:hover': {
                                backgroundColor: 'black',
                                color: '#fff',
                                border: '4px solid #4C61B3'
                            }
                        }}
                    >
                        Acceder
                    </Button>
                </Box>
            </Box>
            {
                loading && <Loader />
            }
            {
                error &&
                <Modal
                    open={open}
                    onClose={handleClose}

                >
                    <Box>
                        <div className="error-background" >
                            <div className="error-wrapper">
                                <h2>ERROR</h2>
                                <p className='error-message'>{errorMessage}</p>
                                <Button
                                    form='billing-form'
                                    variant='outlined'
                                    onClick={handleClose}
                                    sx={{
                                        typography: {
                                            "fontFamily": `"Fira sans", sans-serif`,
                                            "fontSize": 30,
                                            "fontWeightLight": 300,
                                            "fontWeightRegular": 400,
                                            "fontWeightMedium": 500
                                        },
                                        width: 200,
                                        margin: 2,
                                        border: 'none',
                                        borderRadius: 0,
                                        color: '#fff',
                                        background: '#de3c34',
                                        '&:hover': {
                                            backgroundColor: '#fff',
                                            color: '#de3c34',
                                            border: '4px solid #000'
                                        }
                                    }}
                                    className='cancel-button'
                                >
                                    Cerrar
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            }
            <Copyright sx={{ mt: 8 }} />
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}