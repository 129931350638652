import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { Button, Grid, MenuItem, InputAdornment } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import * as Yup from "yup";
import TaxRegime from "./taxRegime";
import paymentMethod from "./paymentMethod";
import month from "./month";
import { useState } from "react";
import Loader from "./loader";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import "../Error.css";

export default function Formulario() {
  const date = new Date();
  let localDate = date.toLocaleDateString();
  let year = date.getFullYear();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: {
      xs: 250,
      sm: 400,
    },
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [succes, setSucces] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    fetch(process.env.REACT_APP_SECRET_URL + "/Bill/v1/Colegio/Count")
      .then((data) => data.json())
      .then((result) => {
        let num = result.numRegistros + 1;
        let str = JSON.stringify(num).split("");
        for (let i = str.length; i < 12; i++) {
          str.unshift("0");
        }
        setId(str.join(""));
      });
  }, []);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 600,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.4,
      border: "1px solid #dadde9",
      p: 20,
    },
  }));

  const validationSchema = Yup.object().shape({
    razonSocial: Yup.string()
      .max(160, "El dato no puede exceder 160 carácteres.")
      .required("Campo obligatorio."),
    rfc: Yup.string()
      .matches(
        /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
        "RFC Invalido, verifique nuevamente."
      )
      .required("Campo obligatorio."),
    regimenFiscal: Yup.string().required("Campo obligatorio"),
    domicilioFiscal: Yup.string()
      .required("Campo obligatorio.")
      .matches(/^[0-9]+$/, "Sólo números.")
      .min(5, "CP debe contener 5 dígitos númericos.")
      .max(5, "CP debe contener 5 dígitos númericos."),
    formaPago: Yup.string().required("Campo Obligatorio."),
    totalFactura: Yup.string()
      .matches(/^[0-9]+$/, "Sólo números.")
      .required("Campo Obligatorio."),
    claveProdServ: Yup.string().required("Campo Obligatorio."),
    periodo: Yup.string().required("Campo Obligatorio."),
    matricula: Yup.string().required("Campo Obligatorio."),
    nombres: Yup.string()
      .matches(
        /^[a-zA-Z\u00C0-\u00FF ]*$/,
        "El campo sólo puede contener letras."
      )
      .max(60, "El dato no puede exceder 60 carácteres.")
      .required("Campo Obligatorio."),
    apellidos: Yup.string()
      .matches(
        /^[a-zA-Z\u00C0-\u00FF ]*$/,
        "El campo sólo puede contener letras."
      )
      .max(60, "El dato no puede exceder 60 carácteres.")
      .required("Campo Obligatorio."),
    curp: Yup.string()
      .matches(
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        "Formato no valido de CURP."
      )
      .required("Campo Obligatorio."),
  });

  const formik = useFormik({
    initialValues: {
      idOperacion: "",
      serie: "CRT - A",
      fecha: localDate,
      divisa: "MXN",
      tipoCambio: "",
      rfcEmisor: "CRT2209234T4",
      rfc: "",
      razonSocial: "",
      domicilioFiscal: "",
      regimenFiscal: "",
      metodoPago: "PUE",
      formaPago: "",
      usoCfdi: "D10",
      totalFactura: "",
      claveProdServ: "",
      claveUnidad: "E48",
      concepto: "Pago de colegiatura",
      cantidad: 1,
      importeUnitario: "",
      descuento: 0,
      retenidos: 0,
      impuesto: "IVA",
      porcentaje: 0,
      importe: 0,
      nombres: "",
      apellidos: "",
      curp: "",
      matricula: "",
      periodo: "",
      email: "",
      confirmEmail: "",
      autRVOE: "-",
      nivelEducativo: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { resetForm }) => {
      let temp = values.claveProdServ.split(" ");
      let nivel = temp[1];
      let clave = temp[0];      
      const factura = {
          usoCfdi: values.usoCfdi,
          totalFactura: values.totalFactura,
          tipoCambio: values.tipoCambio,
          serie: values.serie,
          rfcEmisor: values.rfcEmisor,
          rfc: values.rfc,
          regimenFiscal: values.regimenFiscal,
          razonSocial: values.razonSocial,
          metodoPago: values.metodoPago,
          idOperacion: id,
          formaPago: values.formaPago,
          fecha: "",
          domicilioFiscal: values.domicilioFiscal,
          divisa: values.divisa,
          conceptos: [
            {
              impuesto: {
                traslados: [
                  {
                    porcentaje: values.porcentaje,
                    impuesto: values.impuesto,
                    importe: values.importe,
                  },
                ],
                retenidos: [],
              },
              instEducativas: {
                nombreAlumno: values.nombres + " " + values.apellidos,
                curp: values.curp,
                nivelEducativo: nivel,
                autRVOE: values.autRVOE,
                rfcPago: values.rfc,
              },
              importeUnitario: values.totalFactura.replace(",", ""),
              descuento: values.descuento,
              concepto:
                values.concepto +
                " " +
                values.periodo +
                " " +
                year +
                " Matrícula: " +
                values.matricula +
                " Alumno: " +
                values.nombres +
                " " +
                values.apellidos +
                " CURP: " +
                values.curp,
              claveUnidad: values.claveUnidad,
              claveProdServ: clave,
              cantidad: values.cantidad,
            },
          ],
        },
        registro = {
          idOperacion: id,
          serie: values.serie,
          fecha: values.fecha,
          divisa: values.divisa,
          tipoCambio: values.tipoCambio,
          rfcEmisor: values.rfcEmisor,
          rfc: values.rfc,
          razonSocial: values.razonSocial,
          domicilioFiscal: values.domicilioFiscal,
          regimenFiscal: values.regimenFiscal,
          metodoPago: values.metodoPago,
          formaPago: values.formaPago,
          usoCfdi: values.usoCfdi,
          totalFactura: values.totalFactura,
          claveProdServ: clave,
          claveUnidad: values.claveUnidad,
          concepto: values.concepto,
          cantidad: values.cantidad,
          importeUnitario: values.importeUnitario,
          descuento: values.descuento,
          retenidos: values.retenidos,
          impuesto: values.impuesto,
          porcentaje: values.porcentaje,
          importe: values.importe,
          nombres: values.nombres,
          apellidos: values.apellidos,
          curp: values.curp,
          matricula: values.matricula,
          periodo: values.periodo,
          email: values.email,
          autRVOE: values.autRVOE,
          nivelEducativo: nivel,
        };

      setLoading(true);

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json')

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(factura),
        redirect: 'follow'
      }

      fetch(process.env.REACT_APP_SECRET_URL + '/Bill/v1/Timbrado', requestOptions)
        .then(response => response.json())
        .then(result => {

          let lastElement = result.factura_procesar.length - 1

          if (result.factura_procesar[lastElement].procesar_factura === false) {
            let errorString = JSON.stringify((result.factura_procesar[lastElement].error).replace(/[+]/g, ' '))
            setLoading(false)
            setErrorMessage(errorString)
            setError(true)
            handleOpenErrorModal(true)
          } else {
            setLoading(false)
            setErrorMessage('Factura procesada con éxito')
            setSucces(true)
            handleOpenErrorModal(true)
          }
        })

      const secondHeaders = new Headers()
      secondHeaders.append('Content-Type', 'application/json')

      let options = {
        method: 'POST',
        headers: secondHeaders,
        body: JSON.stringify(registro),
        redirect: 'follow'
      }

      fetch(process.env.REACT_APP_SECRET_URL + '/Bill/v1/Colegio', options)
        .then(response => response.json())
        .then(result => {
          console.log('result', result)
          setLoading(false)
        })
        .catch(error => console.log(error))

      resetForm({ values: "" });
      handleClose();
    },
  });

  return (
    <>
      <div className="back-background">
        <Box
          id="billing-form"
          alignItems="center"
          className="form-background"
          component="form"
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                size="small"
                fullWidth
                htmlFor="razonSocial"
                id="razonSocial"
                name="razonSocial"
                label="Razón Social"
                value={formik.values.razonSocial}
                onChange={formik.handleChange}
                error={
                  formik.touched.razonSocial
                    ? formik.errors.razonSocial
                    : undefined
                }
                onBlur={formik.handleBlur}
              />
              {formik.errors.razonSocial && formik.touched.razonSocial ? (
                <small className="validation-alert">
                  {formik.errors.razonSocial}{" "}
                  <span className="alert-help-message">
                    {" "}
                    Es necesario ingresar datos en este campo
                  </span>
                </small>
              ) : null}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                size="small"
                fullWidth
                htmlFor="rfc"
                id="rfc"
                name="rfc"
                value={formik.values.rfc}
                onChange={formik.handleChange}
                label="RFC"
                error={formik.touched.rfc ? formik.errors.rfc : undefined}
                onBlur={formik.handleBlur}
              />
              {formik.errors.rfc && formik.touched.rfc ? (
                <small className="validation-alert">{formik.errors.rfc} </small>
              ) : null}
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                size="small"
                fullWidth
                select
                htmlFor="regimenFiscal"
                id="regimenFiscal"
                name="regimenFiscal"
                label="Régimen Fiscal"
                value={formik.values.regimenFiscal}
                onChange={formik.handleChange}
                error={
                  formik.touched.regimenFiscal
                    ? formik.errors.regimenFiscal
                    : undefined
                }
              >
                {TaxRegime.map((value) => (
                  <MenuItem key={value.clave} value={`${value.clave}`}>
                    <Typography noWrap>
                      [{value.clave}] {value.descripcion}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
              {formik.errors.regimenFiscal && formik.touched.regimenFiscal ? (
                <small className="validation-alert">
                  {formik.errors.regimenFiscal}
                </small>
              ) : null}
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                size="small"
                fullWidth
                htmlFor="domicilioFiscal"
                id="domicilioFiscal"
                name="domicilioFiscal"
                label="Código Postal"
                value={formik.values.domicilioFiscal}
                onChange={formik.handleChange}
                error={
                  formik.touched.domicilioFiscal
                    ? formik.errors.domicilioFiscal
                    : undefined
                }
                onBlur={formik.handleBlur}
              />
              {formik.errors.domicilioFiscal &&
              formik.touched.domicilioFiscal ? (
                <small className="validation-alert">
                  {formik.errors.domicilioFiscal}
                </small>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                size="small"
                fullWidth
                htmlFor="usoCfdi"
                id="usoCfdi"
                name="usoCfdi"
                label="Uso CFDI"
                value={"Pagos por servicios educativos (colegiaturas)"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                select
                size="small"
                fullWidth
                htmlFor="formaPago"
                id="formaPago"
                name="formaPago"
                label="Forma de Pago"
                value={formik.values.formaPago}
                onChange={formik.handleChange}
                error={
                  formik.touched.formaPago ? formik.errors.formaPago : undefined
                }
                onBlur={formik.handleBlur}
              >
                {paymentMethod.map((value) => (
                  <MenuItem value={value.clave} key={value.clave}>
                    <Typography noWrap>
                      [{value.clave}] {value.descripcion}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
              {formik.errors.formaPago && formik.touched.formaPago ? (
                <small className="validation-alert">
                  {formik.errors.formaPago}
                </small>
              ) : null}
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                size="small"
                fullWidth
                htmlFor="concepto"
                id="concepto"
                name="concepto"
                label="Concepto"
                value={"Pago de colegiatura"}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                select
                size="small"
                fullWidth
                htmlFor="periodo"
                id="periodo"
                name="periodo"
                label="Periodo"
                value={formik.values.periodo}
                onChange={formik.handleChange}
                error={
                  formik.touched.periodo ? formik.errors.periodo : undefined
                }
              >
                {month.map((item) => (
                  <MenuItem value={item.month} key={item.month}>
                    <Typography noWrap>{item.month}</Typography>
                  </MenuItem>
                ))}
              </TextField>
              {formik.errors.periodo && formik.touched.periodo ? (
                <small className="validation-alert">
                  {formik.errors.periodo}
                </small>
              ) : null}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                size="small"
                fullWidth
                htmlFor="matricula"
                id="matricula"
                name="matricula"
                label="Matrícula"
                value={formik.values.matricula}
                onChange={formik.handleChange}
                error={
                  formik.touched.matricula ? formik.errors.matricula : undefined
                }
                onBlur={formik.handleBlur}
              />
              {formik.errors.matricula && formik.touched.matricula ? (
                <small className="validation-alert">
                  {formik.errors.matricula}
                </small>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} fullWidth size="small">
              <TextField
                sx={{ input: { color: "#004693" } }}
                htmlFor="nombres"
                id="nombres"
                name="nombres"
                fullWidth
                size="small"
                label="Nombres del alumno/a"
                value={formik.values.nombres}
                onChange={formik.handleChange}
                error={
                  formik.touched.nombres ? formik.errors.nombres : undefined
                }
                onBlur={formik.handleBlur}
              />
              {formik.errors.nombres && formik.touched.nombres ? (
                <small className="validation-alert">
                  {formik.errors.nombres}
                </small>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                htmlFor="apellidos"
                id="apellidos"
                name="apellidos"
                fullWidth
                size="small"
                label="Apellidos del alumno/a"
                value={formik.values.apellidos}
                onChange={formik.handleChange}
                error={
                  formik.touched.apellidos ? formik.errors.apellidos : undefined
                }
                onBlur={formik.handleBlur}
              />
              {formik.errors.apellidos && formik.touched.apellidos ? (
                <small className="validation-alert">
                  {formik.errors.apellidos}
                </small>
              ) : null}
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                size="small"
                fullWidth
                htmlFor="curp"
                id="curp"
                name="curp"
                label="CURP del alumno/a"
                value={formik.values.curp}
                onChange={formik.handleChange}
                error={formik.touched.curp ? formik.errors.curp : undefined}
                onBlur={formik.handleBlur}
              />
              {formik.errors.curp && formik.touched.curp ? (
                <small className="validation-alert">{formik.errors.curp}</small>
              ) : null}
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                sx={{ input: { color: "#004693" } }}
                select
                fullWidth
                htmlFor="claveProdServ"
                id="claveProdServ"
                name="claveProdServ"
                size="small"
                label="Nivel"
                value={formik.values.claveProdServ}
                onChange={formik.handleChange}
                error={
                  formik.touched.claveProdServ
                    ? formik.errors.claveProdServ
                    : undefined
                }
                onBlur={formik.handleBlur}
              >
                <MenuItem
                  value={"86121501 Preescolar"}
                  key="maternal/preescolar"
                >
                  Maternal/Preescolar
                </MenuItem>
                <MenuItem value={"86121503 Primaria"} key="primaria">
                  Primaria
                </MenuItem>
                <MenuItem value={"86121503 Secundaria"} key="secundaria">
                  Secundaria
                </MenuItem>
              </TextField>
              {formik.errors.claveProdServ && formik.touched.claveProdServ ? (
                <small className="validation-alert">Campo obligatorio</small>
              ) : null}
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                sx={{ input: { color: "#004693", fontWeight: "600" } }}
                size="small"
                htmlFor="totalFactura"
                id="totalFactura"
                name="totalFactura"
                fullWidth
                label="Importe"
                value={formik.values.totalFactura}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                error={
                  formik.touched.totalFactura
                    ? formik.errors.totalFactura
                    : undefined
                }
                onBlur={formik.handleBlur}
              />
              {formik.errors.totalFactura && formik.touched.totalFactura ? (
                <small className="validation-alert">Sólo datos númericos</small>
              ) : null}
            </Grid>
          </Grid>
          <Button
            onClick={handleOpen}
            variant="outlined"
            sx={{
              typography: {
                fontFamily: `"Fira sans", sans-serif`,
                fontSize: 14,
                fontWeightLight: 300,
                fontWeightRegular: 400,
                fontWeightMedium: 500,
              },
              width: 200,
              margin: 2,
              border: "4px solid",
              borderRadius: 0,
              color: "white",
              background: "#4C61B3",
              "&:hover": {
                backgroundColor: "black",
                color: "#fff",
                border: "4px solid #4C61B3",
              },
              "&.Mui-disabled": {
                background: "#A6B0D9",
              },
            }}
            disabled={!(formik.isValid && formik.dirty)}
          >
            Continuar
          </Button>
          <small className="form-advice">*Todos los campos son obligatorios</small>|
        </Box>
        <Modal open={open} onClose={handleClose}>
          <Box className="popup" sx={style}>
            <Typography>
              Ingresar dirección de correo para envío de factura
            </Typography>
            <TextField
              variant="standard"
              label="E-mail"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <TextField
              type={"email"}
              variant="standard"
              label="Confirmar E-mail"
              id="confirmEmail"
              name="confirmEmail"
              value={formik.values.confirmEmail}
              onChange={formik.handleChange}
            />
            <div className="button-container">
              <Button
                form="billing-form"
                variant="outlined"
                onClick={handleClose}
                sx={{
                  typography: {
                    fontFamily: `"Fira sans", sans-serif`,
                    fontSize: 14,
                    fontWeightLight: 300,
                    fontWeightRegular: 400,
                    fontWeightMedium: 500,
                  },
                  width: 200,
                  margin: 2,
                  border: "4px solid white",
                  borderRadius: 0,
                  color: "#004693",
                  background: "#fab52b",
                  "&:hover": {
                    backgroundColor: "#fab52b",
                    color: "#004693",
                    border: "4px solid #E43433",
                  },
                }}
                className="cancel-button"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                form="billing-form"
                variant="outlined"
                sx={{
                  typography: {
                    fontFamily: `"Fira sans", sans-serif`,
                    fontSize: 14,
                    fontWeightLight: 300,
                    fontWeightRegular: 400,
                    fontWeightMedium: 500,
                  },
                  width: 200,
                  margin: 2,
                  border: "4px solid",
                  borderRadius: 0,
                  color: "white",
                  background: "#4C61B3",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "#fff",
                    border: "4px solid #4C61B3",
                  },
                  "&.Mui-disabled": {
                    background: "#A6B0D9",
                  },
                }}
                className="submit-button"
                disabled={
                  formik.values.email !== formik.values.confirmEmail ||
                  !(formik.values.email && formik.values.confirmEmail)
                    ? true
                    : false
                }
              >
                Generar Factura
              </Button>
            </div>
          </Box>
        </Modal>
        <Box className="logo-container">
          <HtmlTooltip
            placement="right-start"
            title={
              <React.Fragment>
                &nbsp;
                <Typography color="inherit">
                  <b>Instrucciones para generar factura</b>
                </Typography>
                <br />
                &nbsp;&nbsp;
                {"1. Rellena las casillas con los datos requeridos."}{" "}
                &nbsp;&nbsp;
                <br />
                &nbsp;&nbsp;
                {
                  "2. Una vez que todos los campos estén debidamente completados, haz clic en el botón 'Continuar'."
                }{" "}
                &nbsp;&nbsp;
                <br />
                &nbsp;&nbsp;
                {
                  "3. Al hacer clic en 'Continuar', se abrirá una ventana emergente donde deberás agregar la dirección de correo electrónico a la que se enviará la factura"
                }{" "}
                &nbsp;&nbsp;
                <br />
                &nbsp;&nbsp;
                {
                  "4. En la ventana emergente, también encontrarás la opción 'Cancelar' en caso de que desees interrumpir el proceso."
                }{" "}
                &nbsp;&nbsp;
                <br />
                &nbsp;&nbsp;
                {
                  "5. Una vez que hayas añadido y confirmado la dirección de correo electrónico para el envío de la factura, haz clic en 'Generar factura'."
                }{" "}
                &nbsp;&nbsp;
                <br />
                &nbsp;&nbsp;
                {
                  "6. Si todos los datos se han completado correctamente, aparecerá un mensaje de éxito que indicará que la factura ha sido generada."
                }{" "}
                &nbsp;&nbsp;
                <br />
                &nbsp;&nbsp;
                {
                  "7. En caso de que ocurra algún error, la factura no podrá ser generada. Será necesario regresar a la plantilla de llenado y verificar si existen mensajes de error en alguno de los campos. Si es necesario, corrige los campos con mensajes de error y vuelve a intentar generar la factura."
                }{" "}
                &nbsp;&nbsp;
                <br />
                {"     "}
                <br />
              </React.Fragment>
            }
          >
            <InfoOutlinedIcon
              sx={{
                color: "#004693",
                position: "relative",
                left: "70%",
              }}
            />
          </HtmlTooltip>
          <img
            className="logo"
            src="logo_colegio_vertical.png"
            alt="logo colegio rotherham"
          ></img>
        </Box>
      </div>
      {error && (
        <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
          <Box>
            <div className="error-background">
              <div className="error-wrapper">
                <h2>Error</h2>
                <p className="error-message">{errorMessage}</p>
                <Button
                  form="billing-form"
                  variant="outlined"
                  onClick={() => {
                    handleCloseErrorModal();
                    setErrorMessage("");
                  }}
                  sx={{
                    typography: {
                      fontFamily: `"Fira sans", sans-serif`,
                      fontSize: 14,
                      fontWeightLight: 300,
                      fontWeightRegular: 400,
                      fontWeightMedium: 500,
                    },
                    width: 200,
                    margin: 2,
                    border: "4px solid",
                    borderRadius: 0,
                    color: "white",
                    background: "#4C61B3",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "#fff",
                      border: "4px solid #4C61B3",
                    },
                  }}
                  className="cancel-button"
                >
                  Cerrar
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {succes && (
        <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
          <Box>
            <div className="error-background">
              <div className="error-wrapper">
                <h2>¡Éxito!</h2>
                <p className="error-message">{errorMessage}</p>
                <Button
                  form="billing-form"
                  variant="outlined"
                  onClick={() => {
                    handleCloseErrorModal();
                    setErrorMessage("");
                  }}
                  sx={{
                    typography: {
                      fontFamily: `"Fira sans", sans-serif`,
                      fontSize: 14,
                      fontWeightLight: 300,
                      fontWeightRegular: 400,
                      fontWeightMedium: 500,
                    },
                    width: 200,
                    margin: 2,
                    border: "4px solid",
                    borderRadius: 0,
                    color: "white",
                    background: "#4C61B3",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "#fff",
                      border: "4px solid #4C61B3",
                    },
                  }}
                  className="cancel-button"
                >
                  Cerrar
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {loading && <Loader />}
    </>
  );
}
