import './App.css'
import { Routes, Route } from "react-router-dom";
import BillingUI from './components/billingUI'
import Login from './components/login'
import React, { useState } from 'react';

function App() {
  const [token, setToken] = useState()

  if (!token) {
    return (
      <Routes>
        <Route path='/' element={<Login setToken={setToken} />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path='/' element={<BillingUI />} />
    </Routes>
  )
}

export default App
