import { HideScroll } from 'react-hide-on-scroll'
import '../App.css'
import MenuDial from './speedDial'

function BillingUI() {
  return (
    <div className="App">
      <HideScroll>
        <header className="App-header">
          <h1>
            FACTURACIÓN
          </h1>
          <p>
            Registra tus datos
          </p>
        </header>
      </HideScroll>
      <MenuDial />
      <footer><p>Colegio Rotherham 2023. All Rights Reserved. Design by niku technology</p></footer>
    </div>
  )
}

export default BillingUI
