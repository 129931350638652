import * as React from 'react'
import Box from '@mui/material/Box'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SearchIcon from '@mui/icons-material/Search';
import PostAddIcon from '@mui/icons-material/PostAdd';
import '../index.css'
import { useState } from 'react'
import Formulario from './form'
import Busqueda from './search'

export default function MenuDial() {

  const [formVisisbility, setFormVisibility] = useState(true)
  const [searchVisibility, setSearchVisibility] = useState(false)

  const handleOpenForm = () => {
    setFormVisibility(true)
    setSearchVisibility(false)
  }

  const handleOpenSearch = () => {
    setFormVisibility(false)
    setSearchVisibility(true)
  }

  return (
    <Box
      className='menu-dial'
    >
      <SpeedDial
        ariaLabel="Menu dial"
        sx={{ position: 'fixed', bottom: 80, left: 16 }}
        className='menu-dial-btn'
        icon={<SpeedDialIcon />}
        FabProps={{
          sx: {
            bgcolor: '#4C61B3',
            '&:hover': {
              bgcolor: 'black',
              border: '4px solid #4C61B3'
            }
          }
        }}
      >
        <SpeedDialAction
          key={'Buscar Factura'}
          icon={<SearchIcon />}
          tooltipTitle={'Buscar Factura'}
          onClick={() => handleOpenSearch()}
        />
        <SpeedDialAction
          key={'Generar Factura'}
          icon={<PostAddIcon />}
          tooltipTitle={'Generar Factura'}
          onClick={() => handleOpenForm()}
        />
      </SpeedDial>
      {
        formVisisbility && <Formulario />
      }
      {
        searchVisibility && <Busqueda />
      }
    </Box>

  )
}
