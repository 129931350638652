import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Grid, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import Loader from "./loader";
import "../Error.css";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Link from "@mui/material/Link";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export default function Busqueda() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 1100,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.4,
      border: "1px solid #dadde9",
      p: 20,
    },
  }));

  const [facturas, setFacturas] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      fecha_inicio: "",
      fecha_fin: "",
      idOperacion: "",
      rfcEmisor: "",
      rfcReceptor: "",
      uuid: "",
      orden: "",
    },

    onSubmit: (values, { resetForm }) => {
      const datos_factura = {
        fecha_inicio: values.fecha_inicio,
        fecha_fin: values.fecha_fin,
        idOperacion: values.idOperacion,
        rfcEmisor: values.rfcEmisor,
        rfcReceptor: values.rfcReceptor,
        uuid: values.uuid,
        orden: values.orden,
      };

      setLoading(true);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      let options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(datos_factura),
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_SECRET_URL + "/Bill/v1/BusquedaFacturasColegio",
        options
      )
        .then((response) => response.json())
        .then((data) => {
          setFacturas(data.data);
          setLoading(false);
        })
        .catch((error) => console.log(error));

      resetForm();
    },
  });

  return (
    <>
      <div className="back-background" id="search-container">
        <Box
          id="search-form"
          alignItems="center"
          className="search-background"
          component="form"
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                type="date"
                size="small"
                fullWidth
                htmlFor="fecha-inicio"
                id="fecha-inicio"
                name="fecha_inicio"
                label="Fecha Inicio"
                InputLabelProps={{ shrink: true }}
                value={formik.values.fecha_inicio}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                type="date"
                size="small"
                fullWidth
                htmlFor="fecha-fin"
                id="fecha-fin"
                name="fecha_fin"
                label="Fecha fin"
                InputLabelProps={{ shrink: true }}
                value={formik.values.fecha_fin}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>

            <Grid item xs={12} md={2.5}>
              <TextField
                size="small"
                fullWidth
                htmlFor="rfc-receptor"
                id="rfc-receptor"
                name="rfcReceptor"
                label="RFC del receptor"
                InputLabelProps={{ shrink: true }}
                value={formik.values.rfcReceptor}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                select
                size="small"
                fullWidth
                htmlFor="orden"
                id="orden"
                name="orden"
                label="Orden"
                value={formik.values.orden}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem key="ASC" value="ASC" selected={true}>
                  Ascendente
                </MenuItem>
                <MenuItem key="DESC" value="DESC">
                  Descendente
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item md={0.5}>
              <IconButton id="btn-gen-fact" type="submit">
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box className="result-container">
          <table>
            <HtmlTooltip
              placement="bottom"
              title={
                <React.Fragment>
                  &nbsp;
                  <Typography color="inherit">
                    <b>Instrucciones para buscar facturas</b>
                  </Typography>
                  <br />
                  &nbsp;&nbsp;
                  {
                    "1. Para buscar todas las facturas de los últimos 30 días, simplemente haz clic en el botón 'Buscar'."
                  }{" "}
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  {
                    "2. Para buscar facturas dentro de un rango de fechas, selecciona la fecha más antigua en el campo 'Fecha Inicio' y la fecha más reciente en el campo 'Fecha Fin'. Luego, haz clic en el botón 'Buscar' y las facturas aparecerán ordenadas en forma descendente, de la más antigua a la más reciente."
                  }{" "}
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  {
                    "3. Para buscar facturas por RFC, simplemente ingresa el RFC del receptor de la factura. Se mostrarán las facturas emitidas al RFC del receptor en los últimos 30 días. Si necesitas buscar facturas para un período mayor a 30 días de un RFC receptor específico, ingresa el RFC y el rango de fechas de emisión de la factura."
                  }{" "}
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  {
                    "4. En la ventana emergente, también encontrarás la opción 'Cancelar' en caso de que desees interrumpir el proceso."
                  }{" "}
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  {
                    "5. Para cambiar el orden en el que se muestran los datos, selecciona la opción 'Ascendente' en la pestaña 'Orden' (para ordenar de más reciente a más antigua) o 'Descendente' (para ordenar de más antigua a más reciente)."
                  }{" "}
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  {
                    "6. Los documentos en formato 'PDF' o 'XML' pueden ser descargados haciendo 'click' en el icono correspondiente."
                  }{" "}
                  &nbsp;&nbsp;
                  <br />
                </React.Fragment>
              }
            >
              <thead>
                <tr>
                  <th>Razón social</th>
                  <th>RFC cliente</th>
                  <th>Fecha</th>
                  <th>Total</th>
                  <th>Serie</th>
                  <th>PDF</th>
                  <th>XML</th>
                </tr>
              </thead>
            </HtmlTooltip>
            <tbody>
              {facturas &&
                facturas
                  .filter((factura) => factura.estadoComprobante === "Timbrada")
                  .map((factura) => (
                    <tr key={factura.id}>
                      <td>
                        {decodeURIComponent(
                          factura.clienteRazonSocial.replace(/([+])/g, " ")
                        )}
                      </td>
                      <td>{factura.clienteRfc}</td>
                      <td>{factura.fechaImprimir}</td>
                      <td>{factura.total}</td>
                      <td>{factura.serie}</td>
                      <td>
                        <Link
                          sx={{ "&:hover": "pointer" }}
                          onClick={() =>
                            openInNewTab(
                              decodeURIComponent(factura.archivo_pdf)
                            )
                          }
                        >
                          {<PictureAsPdfIcon />}
                        </Link>
                      </td>
                      <td>
                        <Link href={decodeURIComponent(factura.archivo_xml)}>
                          <DescriptionIcon />
                        </Link>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </Box>
      </div>
      {loading && <Loader />}
    </>
  );
}
