import React from "react"
import '../Loader.css'

export default function Loader() {
    return (
        <>
            <div className="background">
                <div className="wrapper">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="shadow"></div>
                    <div className="shadow"></div>
                    <div className="shadow"></div>
                    <span>Cargando</span>
                </div>
            </div>
        </>
    )
}