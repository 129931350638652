const month = [
    {
        'month': 'Enero',
    },
    {
        'month': 'Febrero',
    },
    {
        'month': 'Marzo',
    },
    {
        'month': 'Abril',
    },
    {
        'month': 'Mayo',
    },
    {
        'month': 'Junio',
    },
    {
        'month': 'Julio',
    },
    {
        'month': 'Agosto',
    },
    {
        'month': 'Septiembre',
    },
    {
        'month': 'Octubre',
    },
    {
        'month': 'Noviembre',
    },
    {
        'month': 'Diciembre',
    }
]

export default month