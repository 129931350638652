const paymentMethod = [
    {
        'clave': '01',
        'descripcion': 'Efectivo'
    },
    {
        'clave': '03',
        'descripcion': 'Transferencia electrónica de fondos'
    },
    {
        'clave': '04',
        'descripcion': 'Tarjeta de Crédito'
    },
    {
        'clave': '28',
        'descripcion': 'Tarjeta de Débito'
    }
]

export default paymentMethod